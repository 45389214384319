 
const initialState = {
	CRMMenu: {
		OPTIONS: {
			selectedNavProp: 0
		},
		UserOptions: {
			selectedNavProp: 0
		},
		visibleSideNav: "OPTIONS",
		visibleUserSideNav: "UserOptions"
	},
	allUserDetail:[],
	duePayment:[],
	duePaymentEmi: [],
	userDetail:[],
	notification:[],
	crmNotification:[],
	incomingPayment:[],
	latestDocUpload: [],
	leadByNumber: [],
	loanApprovalLead:[],
	loanApprovalLeadGiftCard:[],
	loanApprovalLeadByPriority:[],
	rejectList:[],
	admin:[],
	allStatus:[],
	ambasadorDetails:[],
	loyalityDetails:[],
	allAmbassadorData:[],
	activityData: [],
	ambassadorAllData: [],
	merchantAllData: [],
	disburseLeads: [],
	importantLeads: [],
	leadManagementData: [],
	loanAddaLeadData: []
}

export default initialState;