import React from 'react'
import CarePayLogo from '../../../images/loading 2_2.gif'
const Loader = () => {
    return (
        <div className='carepay-loader'>
            <div className='text-center' >
                <img src={CarePayLogo} width="150px"/>
            </div>
            {/* <div className='loader-component' style={{marginTop:'20px'}}>
                <i className='fa fa-circle-o-notch fa-spin' />
                <span>Please wait...</span>
            </div> */}
        </div>
    )
}

export default Loader;